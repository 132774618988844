<template>
  <v-container>
    <v-card>
      <v-card-title>
        <NavButton class="mb-2 mb-sm-0" />
        <h2 class="mb-0 ml-2" style="word-break: break-word;">Detalles de órdenes de compra</h2>
      </v-card-title>

      <v-card-text>
        <CmProveedoresDashoardView
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable.js';
import { EmptyComponent, SpinnerComponent } from '@/components/utils';
import { NavButton } from '@/components/utils';
import DataTableComponent from '@/components/DataTableComponent.vue';
import CmProveedoresDashoardView from '../../CMProveedoresDashboard/CmProveedoresDashboardView.vue';

export default {
  components: { DataTableComponent, EmptyComponent, SpinnerComponent, NavButton, CmProveedoresDashoardView },
  data: () => ({
    ordenesCompra: createLoadable([]),
    requestId: null,
  }),
  computed: {
    total() {
      return this.ordenesCompra.pagination.total_rows;
    },
    ordenesDeCompra() {
      return this.ordenesCompra.data || [];
    }

  },
  methods: {
    async obtenerDetalleOrdenCompra() {
      toggleLoadable(this.ordenesCompra)
      const { data } = await this.services.cmOrdenesCompra.detalleOrdenesCompra(this.requestId);
      setLoadableResponse(this.ordenesCompra, data)
    }

  },
  created() {
    this.requestId = this.$route.params.id;
  },
}
</script>
